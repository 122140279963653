@font-face {
  font-family: RedHatBold;
  src: local("RedHatDisplay-Bold"),
    url("./fonts/RedHatDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: RedHatMedium;
  src: local("RedHatDisplay-Medium"),
    url("./fonts/RedHatDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: RedHatRegular;
  src: local("RedHatDisplay-Regular"),
    url("./fonts/RedHatDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: RedHatSemiBold;
  src: local("RedHatDisplay-SemiBold"),
    url("./fonts/RedHatDisplay-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: MonumentExtended;
  src: local("MonumentExtended-Regular"),
    url("./fonts/MonumentExtended-Regular.otf") format("opentype");
}

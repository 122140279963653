@import url("./fonts.css");
@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Jost:wght@300;400;500&family=Red+Hat+Display&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #222228;
  text-transform: uppercase;
}

.slick-slide > div {
  padding-right: 20px;
}
